import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

import SelectHolder from '../../SelectHolder'

const EvaluateQualification = (isAdmin, isSuperUser) => {  
	
	const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [qualification, setQualification] = useState("");
    const [organization, setOrganization] = useState("");
    const [showRemoveEvaluatorConfirmation, setShowRemoveEvaluatorConfirmation] = useState(false);
    const [showEndorseConfirmation, setShowEndorseConfirmation] = useState(false);
    const [evaluatorRemoved, setEvaluatorRemoved] = useState(false);
    const [holder, setHolder] = useState("");
    const [requirements, setRequirements] = useState([]);
    const [requirementSets, setRequirementSets] = useState([]);
    const [qualificationVersionUuid, setQualificationVersionUuid] = useState("");
    const [valid, setValid] = useState(false);
    const [endorsementComment, setEndorsementComment] = useState("");
    const [adminComments, setAdminComments] = useState([]);
    const [req, setReq] = useState("");
    const [adminComment, setAdminComment] = useState("");

    const qualificationUuid = useRef("");
    const evaluatorUuid = useRef("");

    const handleCloseEndorseConfirmation = () => setShowEndorseConfirmation(false);
    const handleCloseRemoveEvaluatorConfirmation = () => setShowRemoveEvaluatorConfirmation(false);
    const handleShowRemoveEvaluatorConfirmation = () => setShowRemoveEvaluatorConfirmation(true);

    function formatDate(timestampString) 
    {
        const options = { year: "numeric", month: "long", day: "numeric"}
        return new Date(timestampString).toLocaleDateString(undefined, options)
    }

    const handleShowEndorseConfirmation = (e, requirement) => {
        setReq(requirement);
        setEndorsementComment("");
        setShowEndorseConfirmation(true);
    }

	useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

	const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
		    qualificationUuid.current = queryParameters.get("qualificationUuid");
            evaluatorUuid.current = queryParameters.get("evaluatorUuid");
            let qualificationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/" + qualificationUuid.current, 
                { withCredentials: true, params: { includeRequirements: false } });
            setQualification(qualificationResponse.data);
            let organizationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + qualificationResponse.data.organizationUuid, 
                { withCredentials: true });
            setOrganization(organizationResponse.data);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    const fetchAdminComments = async () => {
        try
        {
            let response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/qualificationHolder/comments/" +
                holder.holderUuid, { withCredentials: true });
            setAdminComments(response.data)
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    const createAdminComment = async () => {
        try
        {
            let comment = {
                comment: adminComment
            }
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/qualification/qualificationHolder/comment/" + holder.holderUuid + "/create", 
                comment, { withCredentials: true });
            setMessage(response.data.message);
            fetchAdminComments();
            setAdminComment("");
            setAlertVariant("primary");
            setMessage(response.message);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    const deleteAdminComment = async (e, qualificationHolderCommentUuid) => {
        try
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + 
                "/qualification/qualificationHolder/comment/" + qualificationHolderCommentUuid + "/delete", 
                { withCredentials: true });
            fetchAdminComments();
            setAlertVariant("primary");
            setMessage(response.message);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    let handleRemoveEvaluator = async (e) => {
        e.preventDefault();
        try 
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/qualification/evaluator/delete/" + evaluatorUuid.current, 
                { withCredentials: true });
            setMessage(response.data.message);
            setAlertVariant("primary");
            setEvaluatorRemoved(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            handleCloseRemoveEvaluatorConfirmation();
        }
    };

    let handleEndorseRequirement = async (e) => {
        e.preventDefault();
        try 
        {
            let comment = {
                uuid: holder.holderUuid,
                comment: endorsementComment
            }
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/qualification/requirement/endorse/" + 
                req.qualificationVersionRequirementUuid + "/" + holder.holderUuid, 
                comment, { withCredentials: true });
            setMessage(response.data.message);
            setAlertVariant("primary");
            let requirementSetResult = await axios.get(process.env.REACT_APP_BASE_API_URL + 
                "/qualification/version/" + qualificationVersionUuid + "/requirementSets", 
                { withCredentials: true } );
            let sortedRequirementSets = requirementSetResult.data.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            let requirementsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/qualification/version/holder/" + holder.holderUuid, 
                { 
                    params: {
                        qualificationVersionUuid: qualificationVersionUuid
                    },
                    withCredentials: true 
                });
            let sortedRequirements = requirementsResponse.data.requirements.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
            const requirementSetsArray = [];
            sortedRequirementSets.forEach((value) => {
                requirementSetsArray.push({
                    uuid: value.uuid,
                    name: value.name,
                    description: value.description,
                    requirements: sortedRequirements.filter((r) =>
                        r.requirementSet &&
                        r.requirementSet.requirementSetUuid === value.uuid
                    )
                });
            });
            let filteredRequirements = sortedRequirements.filter((r) => !r.requirementSet);
            setRequirements(filteredRequirements);
            setRequirementSets(requirementSetsArray);
            setValid(requirementsResponse.data.valid);
            setEndorsementComment("");
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            handleCloseEndorseConfirmation();
        }
    };

    if (evaluatorRemoved)
    {
        return (
            <HelmetProvider>
                <Helmet>
                        <title>{ process.env.REACT_APP_APP_NAME } - Endorse</title>
                </Helmet>
                <div className="p-3 Center-Outer">
                    <div className="List Outer">
                        <h3 className="Center-Text">Evaluate Qualification</h3>
                        {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    </div>
                </div>
            </HelmetProvider>
        )
    }
    else
    {
        return (
            <HelmetProvider>
                <Helmet>
                        <title>{ process.env.REACT_APP_APP_NAME } - Endorse</title>
                </Helmet>
                <div className="p-3 Center-Outer">
                    <div className="List Outer">
                        <h3 className="Center-Text">Evaluate Qualification</h3>
                        {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                        <div className="Details Margin-Bottom">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Qualification: </td>
                                        <td>{qualification.name}</td>
                                    </tr>
                                    {qualification.description &&
                                        <tr>
                                            <td>Description: </td>
                                            <td>{qualification.description}</td>
                                        </tr>
                                    } 
                                    <tr>
                                        <td>Organization: </td>
                                        <td>{organization.name}</td>
                                    </tr>
                                    { holder &&
                                        <tr>
                                            <td>Status: </td>
                                            <td>{valid ? "Valid" : "Invalid"}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <SelectHolder 
                            holder={holder}
                            setHolder={setHolder}
                            setRequirements={setRequirements}
                            qualificationVersionUuid={qualificationVersionUuid}
                            setQualificationVersionUuid={setQualificationVersionUuid}
                            setValid={setValid}
                            requirementSets={requirementSets}
                            setRequirementSets={setRequirementSets}
                            setAdminComments={setAdminComments}
                            isAdmin={isAdmin}
                            isSuperUser={isSuperUser}
                        />
                        { holder &&
                            <div className="Details Margin-Bottom">
                                <h5 className="mb-1">{holder.surname}, {holder.givenName}</h5>
                                <div>
                                    Email: {holder.email}
                                </div>
                                { holder.organization &&
                                    <div>
                                        Organization: {holder.organization}
                                    </div>
                                }
                            </div>
                        }
                        { holder && (isAdmin || isSuperUser) &&
                            <div className="Details Margin-Bottom">
                                <div style={{marginBottom: "5px"}}>Administrator Comments</div>
                                { adminComments.length > 0 && adminComments.map((comment) => {
                                    return(
                                        <div key={comment.uuid}>
                                            <div>
                                                [{formatDate(comment.timestamp)}] {comment.adminsitratorSurname}, {comment.administratorGivenName}
                                                {' '}({comment.administratorEmail}):
                                            </div>
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                {comment.comment}{' '}
                                                <Link onClick={(e) => deleteAdminComment(e, comment.uuid)}>
                                                    [Remove]
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                })}
                                { adminComments.length < 1 &&
                                    <div style={{marginBottom: "5px"}}>
                                        There are no comments.
                                    </div>
                                }
                                <Form onSubmit={createAdminComment} style={{marginTop: "5px"}}>
                                    <Form.Group className="mb-3" controlId="formSurname">
                                        <Form.Control type="text" placeholder="Enter Comment"
                                            value={adminComment}
                                            name="adminComment" 
                                            onChange={(e) => setAdminComment(e.target.value)} 
                                            required />
                                    </Form.Group>
                                    <Button variant="primary" onClick={(e) => createAdminComment(e)}>
                                        Add Comment
                                    </Button>
                                </Form>
                            </div>
                        }
                        { requirementSets.map((requirementSet) => {
                            let iteration = 0;
                            return(
                                <div className="Details Margin-Bottom" key={requirementSet.uuid}>
                                    <h5 className="mb-1">{requirementSet.name}</h5>
                                    <div>
                                        {requirementSet.description}
                                    </div>
                                    { requirementSet.requirements && requirementSet.requirements.map((requirement) => {
                                        iteration = iteration + 1;                                       
                                        return(
                                            <div key={requirement.qualificationVersionRequirementUuid}>
                                                <>
                                                    { iteration > 1 &&
                                                        <div>OR</div>
                                                    }
                                                </>
                                                <div className="list-group">
                                                    <div className="list-group-item list-group-item-action flex-column align-items-start">
                                                        <div className="d-flex w-100 justify-content-between">
                                                            <h5 className="mb-1">{requirement.name}</h5>
                                                            <small>
                                                                {requirement.requirementType.name}
                                                            </small>
                                                        </div>
                                                        {requirement.description &&
                                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                                <p className="mb-1">{requirement.description}</p>
                                                            </div>
                                                        }
                                                        { requirement.valid && requirement.endorsedBy &&
                                                            <div>
                                                                <p className="mb-1">Endorsed by {requirement.endorsedBy} on {formatDate(requirement.endorsed)}.</p>
                                                            </div>
                                                        }
                                                        { !requirement.valid && requirement.requirementType.id !== 2 &&
                                                            <div>
                                                                <p className="mb-1">Not Endorsed</p>
                                                            </div>
                                                        }
                                                        { requirement.valid && requirement.requirementType.id === 2 &&
                                                            <div>
                                                                <p className="mb-1">Valid</p>
                                                            </div>
                                                        }
                                                        { !requirement.valid && requirement.requirementType.id === 2 &&
                                                            <div>
                                                                <p className="mb-1">Invalid</p>
                                                            </div>
                                                        }
                                                        { requirement.comment &&
                                                            <div>
                                                                Comment: {requirement.comment}
                                                            </div>
                                                        }
                                                        { requirement.requirementType.id !== 2 && (!requirement.valid || qualification.qualificationExpirationTypeId === 3) &&
                                                            <div>
                                                                <Button variant="primary" onClick={(e) => handleShowEndorseConfirmation(e, requirement)}>
                                                                    Endorse
                                                                </Button><br/>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                        { requirements.length > 0 &&
                            <div className="Margin-Bottom">
                                { requirements.map((requirement) => {
                                    return (
                                        <div className="list-group" key={requirement.qualificationVersionRequirementUuid}>
                                            <div className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{requirement.name}</h5>
                                                    <small>
                                                        {requirement.requirementType.name}
                                                    </small>
                                                </div>
                                                { requirement.description &&
                                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                                        <p className="mb-1">{requirement.description}</p>
                                                    </div>
                                                }
                                                { requirement.valid && requirement.endorsedBy &&
                                                    <div>
                                                        <p className="mb-1">Endorsed by {requirement.endorsedBy} on {formatDate(requirement.endorsed)}.</p>
                                                    </div>
                                                }
                                                { !requirement.valid && requirement.requirementType.id !== 2 &&
                                                    <div>
                                                        <p className="mb-1">Not Endorsed</p>
                                                    </div>
                                                }
                                                { requirement.valid && requirement.requirementType.id === 2 &&
                                                    <div>
                                                        <p className="mb-1">Valid</p>
                                                    </div>
                                                }
                                                { !requirement.valid && requirement.requirementType.id === 2 &&
                                                    <div>
                                                        <p className="mb-1">Invalid</p>
                                                    </div>
                                                }
                                                { requirement.comment &&
                                                    <div>
                                                        Comment: {requirement.comment}
                                                    </div>
                                                }
                                                { requirement.requirementType.id !== 2 && (!requirement.valid || qualification.qualificationExpirationTypeId === 3) &&
                                                    <div>
                                                        <Button variant="primary" onClick={(e) => handleShowEndorseConfirmation(e, requirement)}>
                                                            Endorse
                                                        </Button><br/>
                                                    </div> 
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>  
                        }
                        { evaluatorUuid.current &&
                            <div className="Form">
                                Remove yourself as an evaluator for this qualification.<br />
                                <Button variant="danger" onClick={handleShowRemoveEvaluatorConfirmation}>
                                    Remove Evaluator
                                </Button><br/>
                            </div>
                        }
                    </div>
                    <Modal show={showEndorseConfirmation} onHide={handleCloseEndorseConfirmation}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Endorsement</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="Margin-Bottom">
                                Are you certain you want to endorse {req.name} for {holder.givenName} {holder.surname}?
                            </div>
                            <Form.Group className="mb-3" controlId="formEndorsementComment">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control type="text" placeholder="Enter Comment"
                                    name="comment" 
                                    onChange={(e) => setEndorsementComment(e.target.value)} />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEndorseConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleEndorseRequirement}>
                            Endorse
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={showRemoveEvaluatorConfirmation} onHide={handleCloseRemoveEvaluatorConfirmation}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Evaluator Removal</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you certain you want to remove yourself as an evaluator for {qualification.name}?</Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseRemoveEvaluatorConfirmation}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={handleRemoveEvaluator}>
                            Remove Evaluator
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>     
            </HelmetProvider>
        );
    }
}

export default EvaluateQualification;